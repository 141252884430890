$green : #78c008; 
$green-darker: #2a9548;
$green-lighter: #00b050; // telecom
$red: #c20e1b;
$red-lighter: #ff0000; // elec
$yellow: #feec00;
$yellow-darker: #dda704; // gaz
$blue: #009de1;
$blue-darker: #0070c0; // water
$brown: #59312f;
$brown-lighter: #80521e; // clean
$background-color: #F9FAFB;
$white: white;
$black-lighter: #212b3623;
$black: #212b36;
$orange: #ed7d31; // consul

$border-radius: 20px;
