@import "./config/config";
$opacity: 0.3;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: Public Sans, sans-serif;
  font-size: 1.5rem;
  color: $black;
}

div.simplebar-placeholder {
  display: none !important;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  cursor: pointer;
}

input {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 14px;
  padding: 0.5rem 0.5rem;
  border: 0;
  color: $black;
}

.MuiModal-root {
  input[type="file"] {
    height: 200px;
  }
}

label {
  max-width: fit-content !important;
}

span.label-inf {
  font-size: 12px !important;
  font-style: italic !important;
  display: inline-block !important;
  min-width: 150px !important;
}

button {
  margin: 1.5rem 0;
  background-color: $green;
  color: $yellow;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: $green-darker;
  }
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_align {
  display: flex;
  align-items: center;
}

.status-like {
  background-color: rgba(120, 192, 8, 0.3);
  text-align: center;
  padding: 5px 10px;
  width: 140px;
  border-radius: 50px;
}

.color_row_site_connection_ {
  &elec {
    background-color: rgba($red-lighter, $opacity);
  }
  &water {
    background-color: rgba($blue-darker, $opacity);
  }
  &gaz {
    background-color: rgba($yellow-darker, $opacity);
  }
  &clean {
    background-color: rgba($brown-lighter, $opacity);
  }
  &telecom {
    background-color: rgba($green-lighter, $opacity);
  }
  &consul {
    background-color: rgba($orange, $opacity);
  }
  &other {
    background-color: rgba($black, $opacity);
  }
}

.MuiModal-root {
  .MuiCard-root {
    max-height: 95vh;
    overflow: auto;
  }
}

